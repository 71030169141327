import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { ListItem } from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import OverflowTooltip from '@local/web-design-system-2/dist/components/OverflowTooltip/OverflowTooltip';
import FileRestoreIcon from '@local/web-design-system-2/dist/icons/FileRestore';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type { SerializedError } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useUpdateFileByIdMutation } from 'src/apiClients/enhancedFileMiddleware';
import type { ListFile } from 'src/apiClients/GENERATED_fileClientEndpoints';
import {
    RESTORE_DIALOG_CANCEL,
    RESTORE_DIALOG_CONFIRM,
    RESTORE_DIALOG_CONTENT,
    RESTORE_FILE_DIALOG_TITLE,
    RESTORE_FILE_TOAST_FAILURE,
    RESTORE_FILE_TOAST_SUCCESS,
    RESTORE_FILE_DIALOG_WARNING,
    VIEW_RESTORED_FILE,
    RESTORE_ACTION_TOOLTIP,
} from 'src/strings';

const RestoreAction = ({ onRestore, canRestore }: RecycleActionProps) => (
    <Grid
        gap={2}
        container
        alignItems="center"
        justifyContent="center"
        item
        xs
        automation-id="file-row-restore-action"
    >
        <Tooltip
            title={RESTORE_ACTION_TOOLTIP}
            placement="bottom"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
            }}
        >
            <span>
                <IconButton
                    disabled={!canRestore}
                    onClick={onRestore}
                    color="secondary"
                    automation-id="file-row-restore-button"
                >
                    <FileRestoreIcon fontSize="small" />
                </IconButton>
            </span>
        </Tooltip>
    </Grid>
);

interface RecycleActionProps {
    onRestore: () => void;
    canRestore?: boolean;
}

interface RecycledFileRowProps {
    file: ListFile;
    fields: FieldDefinition[];
    canRestore: boolean;
}

interface ConfirmRestoreDialogProps {
    open: boolean;
    file: ListFile;
    onClose: (confirm: boolean) => void;
}

const ConfirmRestoreDialog = ({ open, file, onClose }: ConfirmRestoreDialogProps) => (
    <Dialog open={open} onClose={() => onClose(false)} sx={{ maxWidth: '444px', margin: 'auto' }}>
        <DialogTitle automation-id="dialog-title">{RESTORE_FILE_DIALOG_TITLE}</DialogTitle>
        <DialogContent>
            <DialogContentText automation-id="dialog-description">
                <OverflowTooltip title={`"${file.name}"?`} maxLines={2}>
                    {`${RESTORE_DIALOG_CONTENT} "${file.name}"?`}
                </OverflowTooltip>
                {RESTORE_FILE_DIALOG_WARNING}
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
            <Button
                onClick={() => onClose(false)}
                variant="outlined"
                automation-id="dialog-cancel-button"
            >
                {RESTORE_DIALOG_CANCEL}
            </Button>
            <Button
                onClick={() => onClose(true)}
                variant="contained"
                automation-id="dialog-confirm-button"
            >
                {RESTORE_DIALOG_CONFIRM}
            </Button>
        </DialogActions>
    </Dialog>
);

interface ExtendedError extends SerializedError {
    status?: number;
}

export const RecycledFileRow = ({ file, fields, canRestore }: RecycledFileRowProps) => {
    const params = useParams();
    const { addMessage } = useMessagesContext();
    const [updateFileById] = useUpdateFileByIdMutation();
    const [restoreDialogToggle, setRestoreDialogToggle] = useState(false);
    const navigate = useNavigate();

    const organisationId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const onRestore = async (confirm: boolean | null) => {
        setRestoreDialogToggle(false);
        if (confirm) {
            const response = await updateFileById({
                organisationId,
                workspaceId,
                fileId: file.file_id,
                deleted: false,
            });
            const responseError = response?.error as ExtendedError;
            const status = responseError?.status;
            if (status === 303 || !responseError) {
                addMessage({
                    message: RESTORE_FILE_TOAST_SUCCESS,
                    severity: 'success',
                    inlineChildren: true,
                    action: (
                        <Grid container alignItems="center">
                            <Button
                                color="secondary"
                                size="small"
                                sx={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
                                onClick={() => navigate('../files')}
                                automation-id="notification-view"
                            >
                                {VIEW_RESTORED_FILE}
                            </Button>
                        </Grid>
                    ),
                });
            } else {
                addMessage({
                    message: RESTORE_FILE_TOAST_FAILURE,
                    severity: 'error',
                });
            }
        }
    };

    return (
        <>
            <ListItem
                key={file.file_id}
                item={file}
                fields={fields}
                actions={
                    <RestoreAction
                        onRestore={() => setRestoreDialogToggle(true)}
                        canRestore={canRestore}
                    />
                }
                automation-id={`file-row-${file.file_id}-actions`}
                hasDisabledState
            />
            <ConfirmRestoreDialog open={restoreDialogToggle} file={file} onClose={onRestore} />
        </>
    );
};

import { makeStyles } from 'tss-react/mui';

export const FOOTER_HEIGHT_UNITS = 3.5;
export const useStyles = makeStyles()((theme) => ({
    footer: {
        background: theme.palette.background.default,
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'flex-end',
        fontSize: '12px',
        height: theme.spacing(FOOTER_HEIGHT_UNITS),
        marginRight: `${theme.spacing(-2)}!important`,
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        position: 'absolute',
        bottom: 0,
        width: `calc(100vw - ${theme.spacing(2)})`,
    },
    contentInner: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(5),
        height: '100%',
        overflow: 'hidden',
    },
    webVizContent: {
        display: 'grid',
        height: '100%',
        padding: 0,
    },
}));
